export const DEFAULT_PAGE_SIZE = 12;
export const API_URL = process.env.REACT_APP_API_URL;
export const PUBLIC_API_URL = process.env.REACT_APP_API_PUBLIC_URL;
export const ANALYTICS_API_URL = process.env.REACT_APP_ANALYTICS_URL;
export const ACCESS_TOKEN_KEY = "access-token";
export const REFRESH_TOKEN_KEY = "refresh-token";
export const DRAFT_TYPES = { PURCHASE: "PURCHASE" };
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;

const STATUS_ON = "on";
const STATUS_OFF = "off";
export const CHECKBOX_EVENTS = {
  COLUMN_CHECK_EVENT: "ColumnCheck",
  ROW_CHECK_EVENT: "RowCheck",
  UNCHECK_EVENT: "Uncheck",
  CELL_TOGGLE: "CellToggle",
  HEADER_TOGGLE: "HeaderToggle",
  STATUS_ON,
  STATUS_OFF,
  getStatus: selected => (selected ? STATUS_ON : STATUS_OFF)
};

export const STORAGE_DRAFT_ID = "__storage_draftId__";

export const PARAM_DOMAINS = {
  ARTICLE_SALES_UNIT: "ARTICLE_SALES_UNIT",
  FACTOR_CPP_UNIT_TYPE: "FACTOR_CPP_AND_CATALOG_UNIT_TYPE",
  ARTICLE_CATEGORY: "ARTICLE_CATEGORY",
  ARTICLE_LOCATION: "ARTICLE_LOCATION",
  ARTICLE_STATUS: "ARTICLE_STATUS",
  CURRENCY_TYPE: "CURRENCY_TYPE",
  ADDRESS_TYPE: "ADDRESS_TYPE",
  PHONE_TYPE: "PHONE_TYPE",
  COSTING_CONCEPT_TYPE: "COSTING_CONCEPT_TYPE",
  COSTING_CUSTOMS_TYPE: "COSTING_CUSTOMS_TYPE",
  WAREHOUSE: "WAREHOUSE",
  CUSTOMER_CATEGORY: "CUSTOMER_CATEGORY",
  DELIVERY_ZONE: "DELIVERY_ZONE",
  PACKING_TYPE: "PACKING_TYPE",
  LINCOLN_FISCAL_CODE: "RUC",
  EMPLOYEE_RESPONSIBILITY: "EMPLOYEE_RESPONSIBILITY",
  CONCEPT_TYPE: "CONCEPT_TYPE",
  MONEY_RECEIPT_CONCEPT: "MONEY_RECEIPT_CONCEPT",
  VOUCHER_CREDIT_DEBIT_CONCEPT: "VOUCHER_CREDIT_DEBIT_CONCEPT",
  ABSENT_REASON: "ABSENT_REASON",
  ACCOUNTED_AS: "ACCOUNTED_AS",
  RETURN_REQUEST_REASON: "REASON_FOR_RETURN",
  EMPLOYEE_SALARY_MIN: "EMPLOYEE_SALARY_MIN",
  EMPLOYEE_IPS: "EMPLOYEE_IPS",
  EMPLOYEE_MIN_WAGE: "EMPLOYEE_MIN_WAGE",
  EMPLOYEE_MOVEMENT_TYPE: "EMPLOYEE_MOVEMENT_TYPE",
  TRANSITORIO_REASON: "TRANSITORIO_REASON",
  INVOICE_CANCELLATION_REASON: "INVOICE_CANCELLATION_REASON",
  BANKING_TRANSACTIONS: "BANKING_TRANSACTIONS",
  CHECK_RETURN_TYPE: "CHECK_RETURN_TYPE",
  STORE_SALES_TARGET_MOD: "STORE_SALES_TARGET_MOD",
  DISPATCH_POINTS: "DISPATCH_POINTS",
  TRUCK_TYPE: "TRUCK_TYPE"
};

export const DATA_TYPE = {
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
  NUMERIC: "NUMERIC",
  INTEGER: "INTEGER"
};

export const CHECK_STATUS = {
  A_COBRAR: "A_COBRAR",
  CONCILIADO: "CONCILIADO",
  DEVUELTO: "DEVUELTO",
  COBRADO: "COBRADO"
};

export const CHECK_DEPOSITED_STATUS = {
  REBOTADO: "REBOTADO",
  ACEPTADO: "ACEPTADO"
};

export const VOUCHER_EMPLOYEE_STATUS = {
  GENERADO: "GENERADO",
  A_COBRAR: "A_COBRAR",
  EMITIDO: "EMITIDO"
};

export const CHECK_RETURN_TYPE = {
  ADMINISTRACION: "ADMINISTRACION",
  ABOGADO: "ABOGADO",
  SUCURSAL: "SUCURSAL"
};

export const GUARANIES = "GS";
export const DOLARES = "USD";

export const CLIENT_STATUS = {
  ACTIVO: "ACTIVO",
  INACTIVO: "INACTIVO",
  AUTORIZAR: "AUTORIZAR"
};

export const FORMAT = {
  MAXIMUN_FRACTION_DIGITS: 3,
  LOCALE: "en-US",
  CURRENCY: "USD"
};

export const ORDER_STATUS = {
  PRO: "PRO",
  ENC: "ENC",
  ACEPTADA: "RECACEP"
};

export const PHONE_MASKS = {
  CEL: "(0###) ###-###",
  LB: "(021) ###-###",
  INT: false
};

export const SIZE_IMG = {
  MAX: 1000000,
  MIN: 20000
};

export const ZONE_DOMAIN = {
  INTERIOR: "INTERIOR",
  CAPITAL: "CAPITAL"
};

export const TRANSPORTADORA = "TRANSPORTADORA";

export const FILE_TYPES = {
  EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PDF: "application/pdf",
  CSV: "text/csv",
  ONLY_CSV: "csv"
};

export const WEB_REGEXP = /^[a-z0-9]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/m;

export const EMAIL_REGEXP = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/m;

export const USERNAME_REGEXP = /^[a-z]+$/;

export const CI_REGEXP = /^[0-9]{1}[0-9]*[A-Z]?$/;

export const DOC_NUMBER_REGEXP = /^[0-9A-Za-z-]{1,20}$/;

export const RUC_REGEXP = /^[1-9][0-9]*[0-9A-D]?$/;

export const DV_REGEXP = /[0-9]/;

export const FULL_NAME_REGEX = /^[a-zA-Z ]+$/;

export const LETTERS_NUMBERS_REGEX = /^[a-zA-Z0-9]*$/;

export const NUMBERS_REGEX = /^[0-9]*$/;

export const INVOICE_NUMBER_REGEXP = /^[0-9]{3}-[0-9]{3}-[0-9]{7}$/;

export const ARTICLE_TAG_FORMAT_REGEX = /^(?!.*?(,,|, {1},| {2}|, {1}| ,))[ñÑA-Za-z0-9][ ñÑA-Za-z0-9,-]+$/;

/**
 * Dates and time formats
 */

// This is used to save a timestamp in the server.
// Ex.: cretatedAt
export const DATE_TIME_ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss.ms";
export const DATE_ISO_FORMAT = "YYYY-MM-DD";

export const DATE_PATERN_WITH_HYPHEN = "YYYY-MM-DD";

export const DATE_FORMAT = "YYYY/MM/DD";

export const DATE_PICKER_FORMAT = "DD/MM/YYYY";

export const ACTIVE_STATUS = { ACTIVO: "ACTIVO", INACTIVO: "INACTIVO" };

export const TRUCK_TYPE = { CAMION: "CAMION", FURGON: "FURGÓN" };

export const STATUS = {
  ACTIVO: { value: true, label: "ACTIVO" },
  INACTIVO: { value: false, label: "INACTIVO" }
};

export const ADDRESS_TYPE = { MATRIZ: "MATRIZ", SUCURSAL: "SUCURSAL" };

export const ADJUSTMENT_REASON = {
  EXTRAVIO: "EXTRAVIO",
  HURTO: "HURTO",
  RECLAMO: "RECLAMO",
  OTROS: "OTROS"
};

export const COSTING_DETAIL_TYPE = {
  CONCEPT: "COSTING_CONCEPT_TYPE",
  CUSTOMS: "COSTING_CUSTOMS_TYPE"
};

export const COSTING_ENTRY_STATUS = {
  OK: "OK",
  NUEVO: "NUEVO",
  AFECTADO: "AFECTADO",
  REVERSADO: "REVERSADO"
};

export const COSTING_TYPE = {
  IMPORTACION: "IMPORTACION",
  NACIONAL: "NACIONAL"
};

export const INVOICE_COMMENT_TYPE = {
  CAJA_ABIERTA: "CAJA_ABIERTA",
  EMBALAJE_ROTO: "EMBALAJE_ROTO",
  CAJA_HUMEDA: "CAJA_HUMEDA",
  EMBALAJE_FORZADO: "EMBALAJE_FORZADO",
  OTROS: "OTROS"
};

export const INVOICE_STATUS = {
  PENDIENTE: "PENDIENTE",
  INICIAL: "INICIAL",
  PARCIAL: "PARCIAL",
  COMPLETO: "COMPLETO",
  COSTEO: "COSTEO",
  ENTRADA_A_SISTEMA: "ENTRADA_A_SISTEMA"
};

export const ORDER_ITEM_STATUS = {
  PENDIENTE: "PENDIENTE",
  COMPLETO: "COMPLETO",
  CERRADO: "CERRADO",
  ELIMINADO: "ELIMINADO"
};

export const FILTER_TYPE = {
  NOT_EQUAL: "notEqual",
  CONTAINS: "contains",
  STARTS_WITH: "startsWith",
  EQUAL: "equal",
  LIKE: "like",
  LESS_THAN: "lessThan",
  LESS_THAN_OR_EQUAL: "lessThanOrEqual",
  GREATER_THAN: "greaterThan",
  GREATER_THAN_OR_EQUAL: "greaterThanOrEqual",
  IS_NULL: "isNull",
  IN_RANGE: "inRange",
  IN: "in",
  IS_NOT_NULL: "isNotNull"
};

export const CLIENT_CATEGORY = {
  PRECIO_BASE: {
    key: "PRECIO_BASE",
    code: 1,
    label: "PRECIO BASE"
  },
  PRECIO_BASE_DESCUENTOS: {
    code: 2,
    label: "PRECIO BASE DESCUENTOS"
  },
  PRECIO_BASE_SUPER_DESCUENTOS: {
    code: 3,
    label: "PRECIO BASE SUPER DESCUENTOS"
  },
  PRECIO_BASE_DESCUENTOS_ESPECIFICOS: {
    code: 4,
    label: "PRECIO BASE DESCUENTOS ESPECÍFICOS"
  }
};

export const RATE_TYPE = { VENTAS: "VENTAS", DIARIO: "DIARIO" };

export const PROFORMA_COMPLETE_STATUS = {
  COMPLETO: "COMPLETO",
  INCOMPLETO: "INCOMPLETO"
};

export const PROFORMA_STATUS = {
  UNIDAD_DE_CREDITO: "UNIDAD_DE_CREDITO",
  ADMINISTRACION: "ADMINISTRACION",
  OPERADOR_VENTAS: "OPERADOR_VENTAS",
  DEPOSITO: "DEPOSITO",
  FACTURACION: "FACTURACION",
  EMBARQUE: "EMBARQUE",
  EN_TRANSITO: "EN_TRANSITO",
  ENTREGADO: "ENTREGADO",
  SIN_STOCK: "SIN_STOCK",
  VENTAS: "VENTAS"
};

export const PROFORMA_ITEM_SECTOR_STATUS = {
  PREPARACION: "PREPARACION",
  EMPAQUE: "EMPAQUE",
  CONSOLIDACION: "CONSOLIDACION"
};

export const PROFORMA_ORDER_TYPE = {
  BORRADOR: "BORRADOR",
  PRESUPUESTO: "PRESUPUESTO",
  PROFORMA: "PROFORMA"
};

export const PROFORMA_ORDER_STATUS = {
  ACEPTADO: "ACEPTADO",
  CONFIRMADO: "CONFIRMADO",
  RECHAZADO: "RECHAZADO",
  APROBADO: "APROBADO",
  NO_APROBADO: "NO_APROBADO",
  CANCELADO: "CANCELADO"
};

export const TRANSPORT_TYPE = {
  CLIENTE: "CLIENTE",
  PROPIO: "PROPIO",
  TRANSPORTE: "TRANSPORTE"
};

export const CLIENT_TYPE = {
  CASUAL: "CASUAL",
  UVM: "UVM"
};

export const PERSON_TYPE = {
  FISICA: "FÍSICA",
  JURIDICA: "JURÍDICA"
};

export const EMPLOYEE_TYPE = {
  FUNCIONARIO: "FUNCIONARIO",
  CONTRATADO: "CONTRATADO"
};

export const EMPLOYEE_CHARGE = {
  CONDUCTOR: "Conductor",
  CAJERO: "Cajero",
  COBRADOR: "Cobrador",
  FACTURADOR: "Facturador"
};

export const DOCUMENT_TYPE = {
  CI: "CI",
  PASAPORTE: "PASAPORTE"
};

export const CLIENT_DOCUMENT_TYPE = {
  CI: { value: "CI", label: "Cédula Paraguaya" },
  PASAPORTE: { value: "PASAPORTE", label: "Pasaporte" },
  RUC: { value: "RUC", label: "RUC" },
  CEDULA_EXTRANJERA: { value: "CEDULA_EXTRANJERA", label: "Cédula extranjera" },
  CARNET_DE_RESIDENCIA: {
    value: "CARNET_DE_RESIDENCIA",
    label: "Carnet de residencia"
  },
  INNOMINADO: { value: "INNOMINADO", label: "Innominado" },
  TARJETA_DIPLOMATICA: {
    value: "TARJETA_DIPLOMATICA",
    label: "Tarjeta Diplomática de exoneración fiscal"
  },
  OTRO: { value: "OTRO", label: "Otro" }
};

export const DOCUMENT_TYPE_LIST = {
  CEDULA_PARAGUAYA: { name: "Cédula paraguaya", id: 1 },
  PASAPORTE: { name: "Pasaporte", id: 2 },
  CEDULA_EXTRANJERA: { name: "Cédula extranjera", id: 3 },
  CARNET_RESIDENCIA: { name: "Carnet de residencia", id: 4 }
};

export const UVM_INVOICE_STATUS = {
  FACTURADO: "FACTURADO",
  COBRADO: "COBRADO",
  ANULADO: "ANULADO",
  ARCHIVADO: "ARCHIVADO",
  A_COBRAR: "A_COBRAR",
  TRANSITORIO: "TRANSITORIO",
  PROCESADO_PARCIAL: "PROCESADO_PARCIAL"
};

export const PAYMENT_TYPE = {
  CONTADO: "Contado",
  CREDITO_1: "Crédito 7 días",
  CREDITO_2: "Crédito 30 días"
};

export const INVOICE_TYPE_PAYMENT = {
  CONTADO: "Contado",
  CREDITO: "Crédito",
  CREDITO_1: "Crédito 7 días",
  CREDITO_2: "Crédito 30 días"
};

export const TRANSITORY_STATUS = {
  A_COBRAR: "A_COBRAR",
  TRANSITORIO: "TRANSITORIO"
};

export const RETENTION_STATUS = {
  ACEPTADO: "ACEPTADO",
  ANULADO: "ANULADO"
};

export const RECEIPT_TYPES = {
  FACTURACION: "FACTURACIÓN",
  PAGO_A_CUENTA: "PAGO A CUENTA",
  COMISION_BANCARIA: "COMISION_BANCARIA",
  SENAS_DE_IMPORTACION: "SEÑAS DE IMPORTACIÓN"
};

export const SALARY_TYPE = {
  MINIMO: "MINIMO",
  OTRO: "OTRO"
};

export const VOUCHER_TYPE = {
  CREDITO: "CREDITO",
  DEBITO: "DEBITO"
};

export const VOUCHER_STATUS = {
  BORRADOR: "BORRADOR",
  ACTIVO: "ACTIVO",
  ANULADO: "ANULADO"
};

export const RETURN_REQUEST_STATUS = {
  SOLICITUD: "SOLICITUD",
  RECIBIDO: "RECIBIDO",
  APROBADO: "APROBADO",
  RECHAZADO: "RECHAZADO",
  EN_PROCESO: "EN_PROCESO",
  EN_CONTROL: "EN_CONTROL",
  DEPOSITO: "DEPOSITO",
  FINALIZADO: "FINALIZADO",
  SALIDA_A_DEPOSITO: "SALIDA_A_DEPOSITO"
};

export const VOUCHER_CONCEPT = {
  DEVOLUCION: "DEVOLUCION"
};

export const MOVEMENT_TYPE = {
  ANTICIPOS: "ANTICIPOS",
  DESCUENTO_JUDICIALES: "DESCUENTOS JUDICIALES",
  PRESTAMOS: "PRESTAMOS",
  BONIFICACIONES: "BONIFICACIONES",
  VALES: "VALES"
};

export const MOVEMENT_ACCOUNT_TYPE = { DEBITO: "DEBITO", CREDITO: "CREDITO" };

export const EMPLOYEE_VOUCHER_STATUS = {
  GENERADO: "GENERADO",
  APROBADO: "APROBADO",
  ANULADO: "ANULADO"
};

export const EMPLOYEE_SALARY_STATUS = {
  GENERADO: "GENERADO",
  APROBADO: "APROBADO",
  ANULADO: "ANULADO"
};

export const ARTICLE_CLASSIFICATION_COLOR = {
  ROJO_DELETE: "ROJO_DELETE",
  ROJO_REFURNISHED: "ROJO_REFURNISHED",
  AMARILLO: "AMARILLO",
  VERDE: "VERDE"
};

const CLASSIFICATION_TO_COLOR = {
  ROJO_DELETE: "#FC8181",
  ROJO_REFURNISHED: "#FC8181",
  AMARILLO: "#ECC94B",
  VERDE: "#48BB78",
  NINGUNA: ""
};

export const ACCOUNT_TYPE = {
  CTA_CORRIENTE: "CORRIENTE",
  AHORRO: "AHORRO"
};

export const BANK_ACCOUNT_STATUS = {
  ACTIVO: "ACTIVO",
  INACTIVO: "INACTIVO"
};

export function classificationToColor(classifiedAs) {
  return CLASSIFICATION_TO_COLOR[classifiedAs];
}

export const HOLDBACK_CHECK_STATUS = {
  PENDIENTE: "PENDIENTE",
  APROBADO: "APROBADO",
  RESUELTO: "RESUELTO"
};

export const REFERRAL_NOTE_TYPE = {
  NOTA_ENTREGA: "NOTA_DE_ENTREGA",
  NOTA_REMISION: "NOTA_DE_REMISION"
};

export const REFERRAL_NOTE_STATUS = {
  ACEPTADO: "ACEPTADO",
  CONFIRMADO: "CONFIRMADO",
  ENTREGADO: "ENTREGADO",
  ANULADO: "ANULADO",
  PROCESADO: "PROCESADO"
};

export const DEFAULT_BALANCE_LIMIT = 5000;

export const MAX_DISCOUNT_ALLOWED = 99.99;

export const CLIENT_DETAIL = {
  TODOS: "Todos",
  NOMBRES: "Nombres",
  RAZON_SOCIAL: "Razón Social",
  NOMBRE_FANTASIA: "Nombre de Fantasía",
  RUC: "RUC",
  DIRECCION: "Dirección",
  TELEFONO: "Teléfono",
  TIPO_CLIENTE: "Tipo de Cliente",
  NATURALEZA: "Naturaleza",
  EMAIL: "Email",
  GERENTE_COMERCIAL: "Gerente Comercial",
  GERENTE_COMPRAS: "Gerente de Compras",
  GESTOR_COMPRAS: "Gestor de Compras",
  ENCARGADO_COMPRAS: "Encargado de Compras"
};

export const ECOMMERCE_SALE_STATUS = {
  ENTREGADO: "ENTREGADO",
  CANCELADO: "CANCELADO",
  ANULADO: "ANULADO",
  EN_PROCESO: "EN PROCESO",
  PENDIENTE: "PENDIENTE",
  FACTURADO: "FACTURADO"
};

export const ECOMMERCE_SALE_PAYMENT_METHOD = {
  TRANSFERENCIA: "TRANSFERENCIA",
  CREDITO: "TARJETA DE CRÉDITO",
  DEBITO: "TARJETA DE DÉBITO",
  EFECTIVO: "EFECTIVO"
};

export const ARTICLE_RECALL_STATUS = {
  ANULADO: { value: "ANULADO", label: "Anulado" },
  CONFIRMADO: { value: "CONFIRMADO", label: "Confirmado" },
  CANCELADO: { value: "CANCELADO", label: "Cancelado" },
  EN_PREPARACION: { value: "EN_PREPARACION", label: "En preparación" },
  COMPLETADO: { value: "COMPLETADO", label: "Completado" },
  NOTA_IMPRESA: { value: "NOTA_IMPRESA", label: "Nota impresa" },
  ENVIADO: { value: "ENVIADO", label: "Enviado" },
  RECEPCIONADO: { value: "RECEPCIONADO", label: "Recepcionado" }
};

export const ARTICLE_RECALL_LINE_STATUS = {
  PENDIENTE: "PENDIENTE",
  LISTO: "LISTO"
};

export const ARTICLE_RECALL_SECTOR_STATUS = {
  PREPARACION: { value: "PREPARACION", label: "Preparación", color: "orange" },
  COMPLETO: { value: "COMPLETO", label: "Completo", color: "blue" },
  PROCESADO: { value: "PROCESADO", label: "Procesado", color: "green" }
};

export const colorArticleStatus = {
  ACTIVO: "green",
  TEMPORAL: "orange",
  ELIMINADO: "red",
  "NO DISPONIBLE": "magenta"
};

export const AUDIT_PRINCIPAL_DOMAINS = {
  ARTICULO: "ARTICLE"
};

export const ESTABLISHMENT_TYPE = {
  MATRIZ: "MATRIZ",
  SUCURSAL: "SUCURSAL",
  DEPOSITO: "DEPOSITO"
};

export const REDIRECT_FROM_QUERY_PARAM_NAME = "redirectFrom";
export const REDIRECT_FROM_VALUES = ["qrScan"];

export const ALLOWED_RESEND_STATUS_LIST = ["ERROR", "RECHAZADO"];

export const SEND_ERROR_STATUS_LIST = ["ERROR", "ERROR_ENVIO"];
