import { add, substract } from "./math";

export const OperationsByOnOff = {
  on: add,
  off: substract,
  from: statusOrChecked => {
    if (typeof statusOrChecked === "boolean" && statusOrChecked) {
      return add;
    }
    if (typeof statusOrChecked === "string" && statusOrChecked === "on") {
      return add;
    }
    return substract;
  }
};

export function requiredParam(argName) {
  throw new Error(`${argName} is required`);
}

export function articleReducer(result, article) {
  // Ok for reducers.
  // eslint-disable-next-line no-param-reassign
  result[`${article.code}`] = article;
  return result;
}

export function suggestionReducer(result, suggestion) {
  // Ok for reducers.
  // eslint-disable-next-line no-param-reassign
  result[`${suggestion.article.code}`] = suggestion;
  return result;
}

export function articleSuppliersReducer(result, article) {
  if (article.suggestedQuantity > 0) {
    return suggestionReducer(result, article);
  }
  return result;
}

export function createArticlesPriceReducer(articles) {
  return (acc, code) => {
    const price = articles[code] && articles[code].price;
    return acc + (price || 0);
  };
}

// eslint-disable-next-line no-restricted-globals
export const isNumeric = value => !isNaN(value);

export function isNull(obj) {
  return obj === null || obj === undefined;
}

export function findOne(haystack, arr) {
  return arr.some(v => {
    return haystack.indexOf(v) >= 0;
  });
}

export const callAll = (...fns) => (...args) =>
  fns.forEach(fn => fn && fn(...args));

export function valueToString(value) {
  if (typeof value === "string") {
    return value;
  }
  return `${value}`;
}

export function combineArrays(sourceArray, targetArray) {
  // index items by a key property
  const indexedTargetItems = targetArray.reduce((acc, item) => {
    // Ok for reducers.
    // eslint-disable-next-line no-param-reassign
    acc[`${item.id}`] = item;
    return acc;
  }, {});

  const updatedSourceArray = sourceArray.map(item => {
    const updatedItem = indexedTargetItems[item.id];
    if (updatedItem) {
      // already in array, return the modified item
      // but first delete it from the index object
      delete indexedTargetItems[item.id];
      return updatedItem;
    }
    return item;
  });

  // merge the modified source array
  // with the items that were not present before
  const combinedArray = [
    ...updatedSourceArray,
    ...Object.values(indexedTargetItems)
  ];

  return combinedArray;
}

export function removePunctuations(str) {
  return str.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, "");
}

export function getUniqueCatalogsFrom(articleListWithPrices) {
  let allItems = [];
  articleListWithPrices.forEach(art => {
    allItems = [...allItems, ...art.catalogItems];
  });

  // remove duplicates
  const catalogList = [];
  allItems.forEach(item => {
    if (!catalogList.find(unique => unique.catalogId === item.catalogId)) {
      catalogList.push({
        catalogId: item.catalogId,
        catalogName: item.catalogName
      });
    }
  });
  return catalogList;
}

export function getSelectableCellValue({ colDef, data }) {
  const { field } = colDef;
  const rowData = getGridRowData(data, field);
  if (rowData) {
    return rowData.selected;
  }
  return undefined;
}

export function getGridRowData(data, field) {
  const [key, secondKey] = field.split(".");
  if (secondKey) {
    return data[key][secondKey];
  }
  return data[key];
}

export function getSelectableCellLabel({ colDef, data }) {
  const { field } = colDef;
  const rowData = getGridRowData(data, field);
  if (rowData) {
    return rowData.value;
  }
  return undefined;
}

export function deselectOtherCatalogCells(colDef, node, selectableKeys = []) {
  const filterSelectableKeys = key =>
    selectableKeys.includes(key) || key.startsWith("catalogItemsMap");
  const { data } = node;
  Object.keys(data)
    .filter(filterSelectableKeys)
    .forEach(key => {
      const rowData = data[key];
      if (typeof rowData === "object" && rowData !== null) {
        if (!key.startsWith("catalogItemsMap")) {
          node.setDataValue(key, { ...rowData, selected: false });
        } else {
          Object.keys(rowData)
            .filter(subKey => !colDef.field.includes(subKey))
            .forEach(subKey => {
              const nestedFieldKey = `${key}.${subKey}`;
              const nestedRowData = data[key][subKey];
              node.setDataValue(nestedFieldKey, {
                ...nestedRowData,
                selected: false
              });
            });
        }
      }
    });
}

// TODO This method is slow
export function deselectOtherCatalogCellsFoo(colDef, node) {
  const { data } = node;
  const { catalogItemsMap } = data;
  Object.keys(catalogItemsMap)
    .filter(subKey => !colDef.field.includes(subKey))
    .forEach(subKey => {
      const nestedFieldKey = `catalogItemsMap.${subKey}`;
      const nestedRowData = catalogItemsMap[subKey];
      node.setDataValue(nestedFieldKey, {
        ...nestedRowData,
        selected: false
      });
    });
}

export function removeProp(prop) {
  return ({ [prop]: _, ...object }) => {
    return { ...object };
  };
}

export function cellValuesEqual(prevValue, changedValue) {
  const newValue = parseFloat(changedValue);
  const oldValue = parseFloat(prevValue);
  return newValue === oldValue;
}

export const compose = (...fns) => value =>
  fns.reduceRight((accFnResult, currFn) => currFn(accFnResult), value);

export const formatAlphanumeric = value => {
  return value
    .toUpperCase()
    .replace(/\./g, "")
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(".")
    .split("")
    .reverse()
    .join("");
};

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          const [key, value] = param.split("=");
          // eslint-disable-next-line no-param-reassign
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const getQueryParamValue = (location, key) => {
  const urlParams = getQueryStringParams(location.search);
  return urlParams[key] || null;
};

export const formatTypeDocumentNumber = value =>
  value > 1 ? formatAlphanumeric(`${value}`) : "";

export function removeEmpty(obj) {
  if (obj == null) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line no-unused-vars
      ([_, v]) => v !== undefined && v !== null && v !== ""
    )
  );
}

export function serializeToUri(obj) {
  return new URLSearchParams(removeEmpty(obj)).toString();
}

/**
 * Returns a random floating point number
 *
 * @param {minimum value in range} min
 * @param {maximum value in range} max
 * @param {(optionsl) maximum decimal places} maxDecimals
 * @returns
 */
export function randomNumber(min, max, maxDecimals = -1) {
  const num = Math.random() * (max - min + 1) + min;
  if (maxDecimals === -1) {
    return num;
  }
  return Number(num.toFixed(maxDecimals));
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
